// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

function phoneFormat(input) {//returns (###) ###-####
    input = input.replace(/\D/g,'').substring(0,10); //Strip everything but 1st 10 digits
    var size = input.length;
    if (size>0) {input="("+input}
    if (size>3) {input=input.slice(0,4)+") "+input.slice(4)}
    if (size>6) {input=input.slice(0,9)+"-" +input.slice(9)}
    return input;
}


function layout() {
    if(document.getElementsByClassName('field_with_errors').length > 0){
        document.location += "#entry"
    }
    var hamburger = document.getElementsByClassName("hamburger-navigation")[0];
    if(hamburger){
        hamburger.addEventListener("click", function() {
            var menuOptions = document.getElementsByClassName("hamburger-menu-options")[0];
            if(menuOptions.style.display == "block") {
                menuOptions.style.display = "none";
            } else {
                menuOptions.style.display = "block";
            }
        });
    }

    var phoneNumbers = document.querySelectorAll('.phone-number');
    for (var i = 0; i < phoneNumbers.length; ++i) {
        var phoneInput = phoneNumbers[i];
        phoneInput.oninput = function() {
            this.value = phoneFormat(this.value);
        }
    }

}

document.addEventListener("turbolinks:load", layout);
window.onresize = function(){
    resize()
};
Rails.start()
Turbolinks.start()
ActiveStorage.start()